
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import SubEstadoParticipacion from '@/store/entities/OZONE/subEstadoParticipacion'
import { EstadoParticipacion } from '@/store/entities/OZONE/estadoParticipacion'
import { maxResults } from '@/store/entities/page-request'
@Component({
  components: { Modal, BaseButton, BaseCheckbox }
})
export default class EditEstadoParticipacion extends AbpBase {
  name: 'edit-estados-participacion'
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: Boolean, default: false }) editing: boolean
  @Prop({ type: String }) id: string
  subEstadoParticipacion: SubEstadoParticipacion = new SubEstadoParticipacion()
  title: String = ''
  estadoParticipacion: EstadoParticipacion = null
  disabled: boolean = false

  get estadoParticipacionList() {
    return this.$store.state.estadoParticipacion.list
  }

  async created() {
    await this.$store.dispatch({
      type: 'estadoParticipacion/getAll',
      data: maxResults
    })
  }
  async save() {
    if (this.checkFormValidity()) {
      this.subEstadoParticipacion.estadoParticipacionId = this.estadoParticipacion.id
      if (this.editing) {
        await this.$store.dispatch({
          type: 'subEstadoParticipacion/update',
          data: this.subEstadoParticipacion
        })
      } else {
        await this.$store.dispatch({
          type: 'subEstadoParticipacion/create',
          data: this.subEstadoParticipacion
        })
      }

      this.$emit('save-success')
      this.$emit('input', false)
      this.resetFields()
    }
  }
  close() {
    this.$emit('input', false)
    this.resetFields()
  }
  shown() {
    this.estadoParticipacion = null
    if (this.editing) {
      this.subEstadoParticipacion = Util.extend(true, {}, this.$store.state.subEstadoParticipacion.editSubEstadoParticipacion)
      this.estadoParticipacion = this.estadoParticipacionList.find((x) => x.id === this.subEstadoParticipacion.estadoParticipacionId)
      this.title = "Editar subestado";
    } 
    else {
      this.title = "Crear nuevo subestado";
    }
    this.setDisabled()
  }
  checkFormValidity() {
    const valid = (this.$refs.subEstadoParticipacionForm as any).checkValidity()
    return valid
  }
  resetFields() {
    this.subEstadoParticipacion = new SubEstadoParticipacion()
  }

  setDisabled() {
    this.disabled = !abp.auth.isAnyGranted('OZONE.SubEstadoParticipacion.Edit', 'OZONE.SubEstadoParticipacion.Create', 'OZONE.SubEstadoParticipacion.Delete')
  }
  subEstadoParticipacionRule = {
    nombre: {
      required: { required: true, min: 2, max: 32 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Nombre')),
      placeholder: this.L('Nombre'),
      trigger: 'blur'
    },
    estadoParticipacion: {
      required: { filtrableDropdownRequired: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('EstadoParticipacion')),
      placeholder: this.L('EstadoParticipacion'),
      trigger: 'blur'
    }
  }
}
